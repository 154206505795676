<template>
  <div class="h-full flex flex-col justify-center items-center gap-10">
    <span>暂未登录</span>
    <en-button @click="login">登录</en-button>
  </div>
</template>

<script setup lang="ts">
const login = () => {
  window.location.href = import.meta.env.VITE_ENOCLOUD_URL
}
</script>
